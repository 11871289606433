import React from "react";
import { Box, Flex } from "theme-ui";

import Pill from "components/card/Pill";
import FavoriteButton from "components/card/FavoriteButton";
import { IMAGE_RESOLUTIONS } from "components/card/sizes";
import Image from "components/Image";

const Card = ({
  children,
  type = "white",
  size = "large",
  orientation = "portrait",
  image,
  pill,
  onClickFav,
  sx,
  className,
  contentSx,
  ...props
}) => {
  return (
    <Flex
      sx={{
        variant: `cards.${type}`,
        flexDirection: orientation === "landscape" ? "row" : "column",
        ...sx,
      }}
      className={className}
      {...props}
    >
      <Box sx={{ position: "relative", minWidth: "unset" }}>
        {["large", "extra_large"].includes(size) && (
          <Flex
            sx={{
              height: IMAGE_RESOLUTIONS[orientation][size].height,
              width: IMAGE_RESOLUTIONS[orientation][size].width,
              position: "absolute",
            }}
          >
            {pill && <Pill text={pill} />}
            {onClickFav && <FavoriteButton onClick={onClickFav} />}
          </Flex>
        )}
        <Image
          image={image}
          objectFit="cover"
          alt="Card image"
          sx={
            orientation === "portrait"
              ? {
                  height: IMAGE_RESOLUTIONS["portrait"][size]?.height ?? "full",
                  width: IMAGE_RESOLUTIONS["portrait"][size]?.width ?? "full",
                }
              : {
                  minHeight:
                    IMAGE_RESOLUTIONS["landscape"][size]?.height ?? "full",
                  height: "full",
                  width: IMAGE_RESOLUTIONS["landscape"][size]?.width ?? "full",
                }
          }
        />
      </Box>

      <Box
        sx={{
          variant: `cards.children_${type}`,
          flexGrow: "1",
          maxWidth:
            orientation === "landscape"
              ? "70%"
              : IMAGE_RESOLUTIONS["portrait"][size]?.width,
          ...contentSx,
        }}
      >
        {children}
      </Box>
    </Flex>
  );
};

export default Card;
